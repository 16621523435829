import { render, staticRenderFns } from "./RequestDataList.vue?vue&type=template&id=5dce6e04&scoped=true&"
import script from "./RequestDataList.vue?vue&type=script&lang=js&"
export * from "./RequestDataList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dce6e04",
  null
  
)

export default component.exports