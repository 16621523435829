import { render, staticRenderFns } from "./RequestDataMobileView.vue?vue&type=template&id=5fbbc10a&scoped=true&"
import script from "./RequestDataMobileView.vue?vue&type=script&lang=js&"
export * from "./RequestDataMobileView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbbc10a",
  null
  
)

export default component.exports