<template>
    <div class="d-lg-none">
        <div class="row pt-3 mb-5">
            <div class="col-12 col-lg-6">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
            <div class="col-12 col-lg-6">
                <validated-vue-select :options="typeOptions" label="Request Type"
                                      v-model="request_type"/>
            </div>
<!--            <div class="col-12">-->
<!--                <btn @click="loadData" class="btn-block" text="Search"/>-->
<!--            </div>-->
        </div>

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-2">
                <div class="mb-5">
                    <div class="bs-3">
                        <div class="row b-1 fs--1 mt-2 m-0">
                            <div class="font-inter-medium c-line-bottom b-1 py-2 col-12">
                                <div class="">
                                    <div class="pos-r pl-2">
                                        <div class="capitalize-string"></div>
                                        <span class="font-weight-bold">ID</span> : {{ i.id }}
                                    </div>
                                </div>
                            </div>
                            <div class="font-inter-medium c-line-bottom b-1 py-2 col-12">
                                <div class="">
                                    <div class="pos-r pl-2">
                                        <div class="capitalize-string"></div>
                                        <span class="font-weight-bold">Subject</span> : {{ i.subject }}
                                    </div>
                                </div>
                            </div>
                            <span v-if="i.requested_by" class="b-1 w-100 col-6 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Requested User</span> <span class="ml-2">
                                    {{ i.requested_by }}
                                </span>
                            </div>
                        </span>

                            <span v-if="i.requested_type" class="b-1 w-100 col-6 py-2">
                            <div class="p-1">
                               <span class="font-inter-medium">Requested Type </span>
                                <span v-if="i.requested_type===1" class="ml-2">Legal Advisor</span>
                                <span v-if="i.requested_type===2" class="ml-2">Data Sheet</span>
                            </div>
                        </span>

                            <span v-if="i.created_at" class="b-1 w-100 col-6 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Requested At</span>
                                <span class="ml-2"> {{ i.created_at }} </span>
                            </div>
                        </span>
                            <span class="b-1 col-12">
                    <div class="p-1 fl-x fl-j-c py-3">
                        <edit-btn @click="viewDetails(i.id)" icon="" size="sm" class="px-4" text="Details"/>
                    </div>
                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';
import _ from 'lodash';

export default {
    name : 'RequestDataMobileView',

    data () {
        return {
            prev         : '',
            next         : '',
            page         : '1',
            per_page     : '5',
            ext_search   : '',
            request_type : 1,
            typeOptions  : [
                { label : 'Legal Advisor', value : 1 },
                { label : 'Data Sheet', value : 2 }
            ],
            loading : true,
            error   : false,
            details : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    watch : {
        ext_search () {
            this.timeOutLoadData();
        },

        request_type () {
            this.timeOutLoadData();
        }
    },
    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),
        viewDetails (id) {
            this.$router.push('/request-data-list/' + id + '/details/');
        },
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.userList.requestDataList, {
                params : {
                    page         : that.page,
                    per_page     : that.per_page,
                    request_type : that.request_type,
                    ext_search   : that.ext_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }

    }
};
</script>

<style scoped>

</style>
