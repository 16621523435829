<template>
    <inner-page-card class="mb-5" heading="Request Data List">
        <div class="row pt-3">
            <div class="col-12 col-lg-6 d-sm-none-lg-block">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
            <div class="col-12 col-lg-6 d-sm-none-lg-block">
                <validated-vue-select :options="typeOptions" label="Request Type"
                                      v-model="request_type"/>
            </div>
        </div>
        <custom-vue-table class="d-sm-none-lg-block" v-if="currentUser.is_superuser || currentUser.is_ventura_admin" title=""
                   :fields="fields" :url="listUrl" :per-page="5" ref="table"
                   :extra-params="{request_type:request_type, ext_search:ext_search}" :show-search-box="false">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="" text="Details" size="sm" class="px-4" @click="viewDetails(rowData.id)"/>
                </div>
            </template>
            <template #requested_type="{rowData}">
                <div v-if="rowData.requested_type === 1">Legal Advisor</div>
                <div v-if="rowData.requested_type === 2">Data Sheet</div>
            </template>
        </custom-vue-table>
        <div v-else class="text-center py-7">
            <h4 class="font-inter-bold">Permission Denied!</h4>
            <p class="">Sorry, You have no permission to view this page.</p>
        </div>
        <request-data-mobile-view :ext_search="ext_search" :request_type="request_type" class="d-lg-none"/>
    </inner-page-card>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import RequestDataMobileView from './RequestDataMobileView';

export default {
    name       : 'RequestDataList',
    components : { RequestDataMobileView },
    computed   : {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            listUrl      : urls.userList.requestDataList,
            ext_search   : '',
            request_type : 1,
            typeOptions  : [
                { label : 'Legal Advisor', value : 1 },
                { label : 'Data Sheet', value : 2 }
            ],
            fields : [
                {
                    name      : 'sl_no',
                    sortField : 'sl_no',
                    title     : 'Sl. No.'
                },
                {
                    name      : 'subject',
                    sortField : 'subject',
                    title     : 'Subject'
                },
                {
                    name      : 'requested_by',
                    sortField : 'requested_by',
                    title     : 'Requested User'
                },
                {
                    name  : '__slot:requested_type',
                    title : 'Requested Type'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Requested At'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/request-data-list/' + id + '/details/');
        }
    }
};
</script>

<style scoped>

</style>
